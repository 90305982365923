import React, { Component } from 'react';
import Title from '../Title'

class Testimonial extends Component {
    render() {
        return (
            <section className="testimonial_area_two">
                <div className="container">
                    <Title Stitle="Testimonials" pfont=""/>
                    <div id="carouselExampleIndicators" className="carousel slide testimonial_info" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item ">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>MultiversX aspires for its partners to make use of the security, efficiency and ever-developing network to build and enable the adopters of the network even more, because with such an integrating model, MultiversX can focus on developing the infrastructure and core technologies. Titan Stake took the initiative to prove to the whole ecosystem that adopters should be completing not competing, and accordingly they started a series of partnerships with other projects, builders and even staking providers to add concrete value to the ecosystem.</p>
                                </div>
                            </div>
                            <div className="carousel-item active">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>We are always encouraging our partners and especially the staking providers to do more than being validators. We know their potential, it is a crucial part of why we partnered with all of them, and that is why we believe that they can do more and through enabling the network  they will grow even more, and that is where Titan Stake is set as an example for how a partner should be, how an ambassador can be and how an organization can grow through enabling others on the network and contribute in onboarding more ideas, builders, entrepreneurs and investors to the network.</p>
                                </div>
                            </div>
                            <div className="carousel-item ">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>Most individuals are on the crypto scene mainly because of their objective to make money, and having a passive income for staking your assets is one of the most known ways to grow your capital, but you have to choose closely who you stake your assets with as you do not want any down time with their nodes, to guarantee the continuous money flow, as well as to make sure of their security measures and I can comfortably say that I do not regret choosing Titan Stake for these factors and I really recommend them.</p>
                                </div>
                            </div>
                        </div>
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="">
                                <div className="slider_thumbs"><img src={require('../../images/dan-voicu@2x.png')}  alt="Dani Voicu"/>
                                        <div className="thumbs_text">
                                        <h2>Dani Voicu </h2>
                                        <p>HEAD OF MARKETING - MultiversX</p>
                                    </div>
                                </div>
                            </li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1" className="active">
                                <div className="slider_thumbs"><img src={require('../../images/lucian-mincu@2x.png')}  alt="Lucian Mincu"/>
                                    <div className="thumbs_text">
                                        <h2>Lucian Mincu</h2>
                                        <p>CO-FOUNDER & CIO - MultiversX</p>
                                    </div>
                                </div>
                            </li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2" className="">
                                <div className="slider_thumbs"><img src={require('../../images/ruben_man.png')}  alt="Ruben Man"/>
                                        <div className="thumbs_text">
                                        <h2>Ruben Man</h2>
                                        <p>MultiversX enthusiast</p>
                                    </div>
                                </div>
                            </li>
                        </ol>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"><i className="ti-arrow-left"></i></a><a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"><i className="ti-arrow-right"></i></a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonial;