import React, { Component } from 'react';

class About extends Component {
    constructor(){
        super()
        this.state = {
            title:  'From Active Staker to Ecosystem Co-Builder',
            text:  'Titan Stake is one of the most active staking service providers în the MultiversX Ecosystem, and aims to be an ever-growing name that leads the way în building on the network, educating the public and hunting top talents and ideas, adopt them until they flourish and add more value to the network.',
            text2:  'Titan Stake is în the phase of repositioning the brand’s values and growth path and this project is a crucial milestone along the way.',
        }
    }
    render(){
        return(
            <section className="about_area" id="aboutus">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require('../images/line/line_01.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../images/line/line_02.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../images/line/line_03.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../images/line/line_04.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../images/line/line_05.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../images/line/line_06.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../images/line/shape.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../images/line/shape-1.png')} alt="f_img" /></li>
                </ul><img className="shape wow fadeInRight" src={require('../images/greek-shape.png')} alt="f_img" />
                <div className="container">
                    <div className="row row_reverse align_items_center">
                        <div className="col-lg-7">
                            <div className="about_img text-right"><img src={require('../images/projects.png')} alt="f_img" /></div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about_content">
                                <h2 className="h_color f_36 wow fadeInUp">{this.state.title}</h2>
                                <div className="br"></div>
                                <div className="wow fadeInUp" data-wow-delay="300ms">
                                    <p>{this.state.text}</p>
                                    <p>{this.state.text2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default  About;