import React, { Component } from 'react';
import Title from '../Title';
import $ from 'jquery';

class Mission extends Component {
    componentDidMount(){
        $(".faq_accordian_two .card").click(function(){
            $(".faq_accordian_two .card").removeClass("active");
            $(this).addClass('active');
        });
    }
    render() {
        return (
            <section className="faq_solution_area features_area_pad" id="mission">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require ('../../images/line/fq_line-1.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../../images/line/fq_line-2.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../../images/line/fq_line-3.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../../images/line/fq_line-4.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../../images/line/fq_line-5.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../../images/line/fq_line-6.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../../images/line/fq_line-7.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../../images/line/fq_line-8.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": -150}'><img src={require('../../images/line/fq_line-9.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": -180}'><img src={require('../../images/line/fq_line-10.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="Our Mission" pfont="Join the Titans and Secure the MultiversX network."/>
                    <div className="row ">
                        <div className="col-md-6 d_flex">
                            <div id="accordion" className="faq_accordian_two">
                                <div className="card wow fadeInUp active">
                                    <div className="card-header" id="heading1">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse0" aria-expanded="true" aria-controls="collapse0"><i className='ti-gallery'></i>NFTs</button></h5>
                                    </div>
                                    <div id="collapse0" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Debentures and capital gatherer, to assist în formulating our builder identity, incubate prosperous ideas, create new vacancies and have more partnerships.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading2">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1"><i className='ti-blackboard'></i>Titan Academy </button></h5>
                                    </div>
                                    <div id="collapse1" className="collapse " aria-labelledby="heading2" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Qualifying talents and calibers to join the blockchain industry, whilst creating the educational and community hype that we need to become as influential as we can în the community generally and among young talents specifically.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading3">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2"><i className='ti-info'></i>Titan Stake </button></h5>
                                    </div>
                                    <div id="collapse2" className="collapse " aria-labelledby="heading3" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Grows bigger as an entity, a community and people who are familiarized to the network through us will have faith and trust în us and our staking pool will grow as a result.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading4">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3"><i className='ti-briefcase'></i>Egold Jobs </button></h5>
                                    </div>
                                    <div id="collapse3" className="collapse " aria-labelledby="heading4" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Egold Jobs will act as a portal for the talents who aspire to join the industry whether as employees or interns and through the website will be able to enable our projects even more and gain more reputation aș the go-to entity if you want to join the future.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="faq_image_mockup wow fadeInUp" data-wow-delay="200ms"><img src={require('../../images/block-chain.png')} alt="featured"/></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Mission;