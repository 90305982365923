import axios from "axios";
import SessionStorageService from "../services/sessionStorageService/SessionStorageService";
import {SessionStorageKeys} from "../services/sessionStorageService/sessionStorageKeys";

interface GetProvidersType {
    apiAddress: string;
    identity: string;
    timeout?: number;
    page?: number;
    url?: string;
}

const fetchProvider = (url: string) =>
    async function getProvider({apiAddress, identity, timeout}: GetProvidersType) {
        try {
            const getSessionProvider = SessionStorageService.getItem(SessionStorageKeys.providerData);
            if(getSessionProvider){
                return getSessionProvider;
            }

            const {data} = await axios.get(`${apiAddress}${url}`, {
                params: {
                    identity: identity,
                },
                timeout
            });

            if(data.length !== 1){
                return undefined;
            }

            const response = {
                data: data[0],
                success: data[0] !== undefined
            };
            SessionStorageService.setItem(SessionStorageKeys.providerData, response, 600);

            return response;
        } catch (err) {
            return {
                success: false
            };
        }
    };

export const getProvider = fetchProvider("/providers");
