import React, {Component} from 'react';

class Staking extends Component {
    render() {
        let imgUrl = require('../images/get_bg.png')
        const divStyle = {
            background: 'linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(' + imgUrl + ') no-repeat',
        }
        const {nodesNumber, delegatorsNumber, egldDelegated, serviceFee, currentApr, egldUsdcValue} = this.props;
        return (
            <section className="new_call_action_area" style={divStyle} id="staking">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card-item px-2 py-3 d-flex align-items-center">
                                <div className="card-item-icon mr-4"><i className='ti-user'></i></div>
                                <div className="min-w-0 w-100">
                                    <div className="card-item-title font-weight-bold">Delegators</div>
                                    <div className="card-item-value d-flex flex-row flex-nowrap">
                                        <div>{delegatorsNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-item px-2 py-3 d-flex align-items-center">
                                <div className="card-item-icon mr-4">
                                    <i className='ti-harddrives'></i>
                                </div>
                                <div className="min-w-0 w-100">
                                    <div className="card-item-title font-weight-bold">Nodes</div>
                                    <div className="card-item-value d-flex flex-row flex-nowrap">
                                        {nodesNumber}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-item px-2 py-3 d-flex align-items-center ">
                                <div className="card-item-icon mr-4"><i className='ti-server'></i></div>
                                <div className="min-w-0 w-100">
                                    <div className="card-item-title font-weight-bold">APR</div>
                                    <div className="card-item-value d-flex flex-row flex-nowrap">
                                        <div>{currentApr}%/year</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-item px-2 py-3 d-flex align-items-center">
                                <div className="card-item-icon mr-4"><i className='ti-money'></i></div>
                                <div className="min-w-0 w-100">
                                    <div className="card-item-title font-weight-bold">Service Fee</div>
                                    <div className="card-item-value d-flex flex-row flex-nowrap">
                                        <div>{serviceFee * 100}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card-item px-2 py-3 d-flex align-items-center">
                                <div className="card-item-icon mr-4"><i className='ti-shield'></i></div>
                                <div className="min-w-0 w-100">
                                    <div className="card-item-title font-weight-bold">EGLD Delegated</div>
                                    <div className="card-item-value d-flex flex-row flex-nowrap">
                                        <p>{egldDelegated.toLocaleString('en-US', {
                                            maximumFractionDigits: 0
                                        })} EGLD
                                            (${Number(egldUsdcValue * egldDelegated).toLocaleString('en-US', {
                                                maximumFractionDigits: 0
                                            })})
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Staking;