import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer-five">
                <div className="footer-top">
                    <div className="container">
                        <div className="row footer_sidebar">
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget1 about_us_widget"> 
                                <Link to='/'>
                                    <span className="logo-bottom">
                                        <img src={require('../../images/logo-titan-white.png')} alt="Titan Stake"/>
                                    </span>
                                </Link>
                                <p>Join the Titans and Secure the MultiversX network. Your stake is 100% secured by MultiversX smart contract</p>
                            </div>
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget4 widget_social">
                                <h4 className="widget_title_two">Social Link</h4>
                                <ul className="social-icon">
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TitanStakeAgency"><i className="fa fa-facebook"></i>Facebook</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/titanstake/"><i className="fa fa-instagram" aria-hidden="false"></i>Instagram </a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://t.me/titanstake"><i className="fa fa-paper-plane"></i>Telegram </a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/titanstake"><i className="fa fa-twitter"></i>Twitter </a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://medium.com/@TitanStake"><i className="fa fa-medium"></i>Medium </a></li>
                                </ul>
                            </div>
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget_nav_menu" data-wow-delay="0ms" data-wow-duration="1500ms" data-wow-offset="0">
                                <h4 className="widget_title_two">Links</h4>
                                <div className="menu-quick-links-container">
                                    <ul id="menu-quick-links" className="menu">
                                        <li id="menu-item-844" className="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://maiartokens.com/">Maiar Tokens</a></li>
                                        <li id="menu-item-845" className="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://entity.global/">Entity</a></li>
                                        <li id="menu-item-846" className="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://egldscan.com/">eGLDScan</a></li>
                                        <li id="menu-item-847" className="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://egoldjobs.com/">Egold Jobs</a></li>
                                        <li id="menu-item-847" className="menu-item"><a target="_blank" rel="noopener noreferrer" href="https://multiversx.com/">MultiversX</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget2 widget_contact">
                                <h4 className="widget_title_two">Contact Info</h4>
                                <div className="widget_inner row m0">
                                    <ul>
                                        <li><i className="ti-location-pin"></i>
                                            <div className="location_address fleft">Sibiu, Romania </div>
                                        </li>
                                        <li><i className="ti-email"></i>
                                            <div className="fleft contact_mail"><a href="mailto:office@titanstake.com"> office@titanstake.com </a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pt-4">
                                    <iframe title="myFrame" src="https://egld.community/api/products/ff69456e-028e-44a8-966f-f02e1a9423ff/upvotes/embed?theme=primary&size=sm" width="136" height="40" style={{"border-radius": "8px", border: "none", width: "136px", height: "40px"}}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m0 footer_bottom text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p>{new Date().getFullYear()} <a href="https://titanstake.com">Titan Stake</a>. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;