import React, {Component} from 'react';
import {Range, getTrackBackground} from 'react-range';

export const maxSlide = 50000;

class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [1],
            compound: [1],
            dailyRewards: 0,
            monthlyRewards: 0,
            yearlyRewards: 0,
            dailyCompoundRewards: 0,
            monthlyCompoundRewards: 0,
            yearlyCompoundRewards: 0
        };

        this.inputRewardsChange([1]);
        this.inputRewardsCompoundChange([1]);
    }

    componentDidMount() {
        this.inputRewardsChange([1]);
        this.inputRewardsCompoundChange([1]);
    }

    componentWillReceiveProps() {
        this.inputRewardsChange([1]);
        this.inputRewardsCompoundChange([1]);
    }

    inputRewardsChange = (values) => {
        const currentApr = this.props.currentApr ? this.props.currentApr : 8;
        if (!values || values.length !== 1) {
            return undefined;
        }

        const delegatedEgld = values[0];
        const dailyRewards = Number(delegatedEgld * (currentApr / 100) / 365);
        this.setState({
            values: values,
            dailyRewards,
            monthlyRewards: dailyRewards * 30,
            yearlyRewards: dailyRewards * 365
        }, () => {
            this.inputRewardsCompoundChange(this.state.compound);
        });
    }

    inputRewardsCompoundChange = (compound) => {
        const currentApr = this.props.currentApr ? this.props.currentApr : 8;
        if (!compound || compound.length !== 1) {
            return undefined;
        }

        const delegatedEgld = this.state.values[0];
        const compoundYear = compound[0];
        const dailyCompoundRewards = this.getCompoundInterest(delegatedEgld, currentApr / 100, compoundYear, 365);
        const monthlyCompoundRewards = this.getCompoundInterest(delegatedEgld, currentApr / 100, compoundYear, 12);
        const yearlyCompoundRewards = this.getCompoundInterest(delegatedEgld, currentApr / 100, compoundYear, 1);

        this.setState({
            compound: compound,
            dailyCompoundRewards,
            monthlyCompoundRewards,
            yearlyCompoundRewards
        });
    }

    getCompoundInterest(principalAmount, annual_rate, t_years, n_times) {
        if (Number(principalAmount) === 0) {
            return 0;
        }

        const amount = principalAmount * (Math.pow((1 + (annual_rate / n_times)), (n_times * t_years)));
        //0.0002 fees per restake
        return amount - principalAmount - (n_times * 0.0002);
    }

    handleChange(e) {
        if (!e) {
            this.setState({values: []});
            return;
        }

        if (e > maxSlide || !/^[0-9]+$/.test(e)) {
            return undefined;
        } else {
            this.inputRewardsChange([e]);
        }
    }

    render() {
        const {egldUsdcValue, currentApr} = this.props;
        return (
            <div id="calculator">
                <div id="simulation" data-aos="fade-in" data-aos-easing="linear"
                     data-aos-delay="400" data-aos-duration="500">
                    <span className="headline">
                        <span className="title">Staking Rewards Calculator
                            <span className="float-right">eGLD: ${egldUsdcValue.toLocaleString('en-US')} </span>
                        </span>
                    </span>
                    <div className="mx-3 px-2 px-lg-0 mx-lg-5 mt-2"></div>
                    <div className="inputs">
                        <div className="label d-flex justify-content-between mb-0 mr-0 row">
                            <div className="col-8">
                                <div className="mb-2 mb-lg-3">
                                    Your EGLD stake
                                </div>
                                <span className="value" id="amount">
                                    <input className="mr-2 input-egld"
                                           onChange={(e) => {
                                               this.handleChange(e.target.value)
                                           }}
                                           value={this.state.values[0]}
                                           type="number"/>
                                    <i className='ti-pencil'></i>
                                </span>
                            </div>
                            <div className="text-right col-4">
                                <div className="mb-2 mb-lg-3">
                                    Yearly Return
                                </div>
                                <span className="value" id="percent">+ {currentApr}%</span>
                            </div>
                        </div>
                        <div className="first-slide mt-3">
                            <Range
                                values={this.state.values}
                                step={1}
                                min={1}
                                max={maxSlide}
                                onChange={(values) => this.inputRewardsChange(values)}
                                renderTrack={({props, children}) => (
                                    <div
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                            ...props.style,
                                            height: "36px",
                                            display: "flex",
                                            width: "100%"
                                        }}
                                    >
                                        <div
                                            ref={props.ref}
                                            style={{
                                                height: "10px",
                                                width: "100%",
                                                borderRadius: "4px",
                                                background: getTrackBackground({
                                                    values: this.state.values,
                                                    colors: ["#1e8deb", "#ccc"],
                                                    min: 1,
                                                    max: maxSlide
                                                }),
                                                alignSelf: "center"
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                )}
                                renderThumb={({props, isDragged}) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: "24px",
                                            width: "24px",
                                            borderRadius: "4px",
                                            backgroundColor: "#FFF",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            boxShadow: "0px 2px 6px #AAA"
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "16px",
                                                width: "5px",
                                                backgroundColor: isDragged ? "#548BF4" : "#CCC"
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="results">
                        <div className="container px-0">
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Daily
                                </div>
                                <div className="col-7 col-md-6 text-md-right daily value">
                                    <span
                                        id="daily-erd">{this.state.dailyRewards.toLocaleString('en-US', {maximumFractionDigits: 5})}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right value daily">
                                    $<span
                                    id="daily-usd">{(this.state.dailyRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Monthly
                                </div>
                                <div className="col-7 col-md-6 text-md-right monthly value">
                                    <span
                                        id="monthly-erd">{this.state.monthlyRewards.toLocaleString('en-US')}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right monthly value">
                                    $<span
                                    id="monthly-usd">{(this.state.monthlyRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Yearly
                                </div>
                                <div className="col-7 col-md-6 text-md-right yearly value">
                                    <span id="yearly-erd">{this.state.yearlyRewards.toLocaleString('en-US')}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right yearly value">
                                    $<span
                                    id="yearly-usd">{(this.state.yearlyRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inputs">
                        <span className="font-bold">Rewards Compound Calculator</span>
                        <p>The values below are calculated based on the periods in which rewards are re-delegated.</p>
                    </div>

                    <div className="results second">
                        After {this.state.compound[0].toFixed(0)}
                        {this.state.compound[0] === 1 && <> year</>}
                        {this.state.compound[0] > 1 && <> years</>}
                        <Range
                            values={this.state.compound}
                            step={1}
                            min={1}
                            max={5}
                            onChange={(compound) => this.inputRewardsCompoundChange(compound)}
                            renderTrack={({props, children}) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: "36px",
                                        display: "flex",
                                        width: "100%"
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: "10px",
                                            width: "100%",
                                            borderRadius: "4px",
                                            background: getTrackBackground({
                                                values: this.state.compound,
                                                colors: ["#1e8deb", "#ccc"],
                                                min: 1,
                                                max: 5
                                            }),
                                            alignSelf: "center"
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({props, isDragged}) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "24px",
                                        width: "24px",
                                        borderRadius: "4px",
                                        backgroundColor: "#FFF",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 2px 6px #AAA"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "16px",
                                            width: "5px",
                                            backgroundColor: isDragged ? "#548BF4" : "#CCC"
                                        }}
                                    />
                                </div>
                            )}
                        />
                        <div className="container px-0">
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Daily
                                </div>
                                <div className="col-7 col-md-6 text-md-right daily value">
                                    <span
                                        id="daily-erd1">{this.state.dailyCompoundRewards.toLocaleString('en-US')}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right value daily">
                                    $<span
                                    id="daily-usd1">{(this.state.dailyCompoundRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Monthly
                                </div>
                                <div className="col-7 col-md-6 text-md-right monthly value">
                                    <span
                                        id="monthly-erd1">{this.state.monthlyCompoundRewards.toLocaleString('en-US')}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right monthly value">
                                    $<span
                                    id="monthly-usd1">{(this.state.monthlyCompoundRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between">
                                <div className="col-12 col-md-2 label m-0">
                                    Yearly
                                </div>
                                <div className="col-7 col-md-6 text-md-right yearly value">
                                    <span
                                        id="yearly-erd1">{this.state.yearlyCompoundRewards.toLocaleString('en-US')}</span> EGLD
                                </div>
                                <div className="col-5 col-md-4 text-right yearly value">
                                    $<span
                                    id="yearly-usd1">{(this.state.yearlyCompoundRewards * egldUsdcValue).toLocaleString('en-US')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Calculator;


