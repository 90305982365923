import React, { Component } from 'react';

class Team extends Component {
    constructor(){
        super()
        this.state = {
            title:  'From Active Staker to Ecosystem Co-Builder',
            text:  'Titan Stake is one of the most active staking service providers în the MultiversX Ecosystem, and aims to be an ever-growing name that leads the way în building on the network, educating the public and hunting top talents and ideas, adopt them until they flourish and add more value to the network.',
            text2:  'Titan Stake is în the phase of repositioning the brand’s values and growth path and this project is a crucial milestone along the way.',
        }
    }
    render(){
        return(
            <section className="team_area_solid feature-bg" id="team">
                <div className="container">
                    <div className="title-four text-center">
                        <h2> Our Team </h2>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Taranu Claudiu" src={require('../images/claudiu_taranu_avatar.jpeg')} />
                                <div className="content">
                                    <h2>Taranu Claudiu</h2>
                                    <h6>Co-Founder Titan Stake <br /> Fullstack Developer</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/claudiu.taranu.7/"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/TClauzi"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Fodor Cristian"  src={require('../images/cristian_fodor_avatar.jpeg')} />
                                <div className="content">
                                    <h2>Fodor Cristian</h2>
                                    <h6>Co-Founder Titan Stake <br /> Fullstack Developer</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/cristii.fodor"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/ICristianFodor"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Taranu Daniel" src={require('../images/daniel_taranu_avatar.jpeg')} />
                                <div className="content">
                                    <h2>Taranu Daniel</h2>
                                    <h6>Co-Founder Titan Stake <br /> Business Development</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/daniel.taranu.5"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/TaranuDaniel2"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Taranu Darius" src={require('../images/darius_taranu_avatar.jpeg')} />
                                <div className="content">
                                    <h2>Taranu Darius</h2>
                                    <h6>Co-Founder Titan Stake <br /> Business Development</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/darius.taranu"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/DariusTaranu"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Moamen Magdy Bakry" src={require('../images/moa_avatar.jpeg')} />
                                <div className="content">
                                    <h2>Moamen Magdy Bakry</h2>
                                    <h6>Project Manager</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/N.Vesuvius"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="team_member">
                                <img alt="Titan" src={require('../images/titan.png')} />
                                <div className="content">
                                    <h2>You</h2>
                                    <h6>Open position</h6>
                                    <h6 className="mb-4">Full Stack Developer</h6>
                                    <div className="social">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TitanStakeAgency"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/titanstake"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/titanstake"><i className="fa fa-paper-plane"></i> </a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/titan-stake"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default  Team;
