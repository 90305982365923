import React, { Component } from 'react';

class Partners extends Component {
    render() {
        let imgUrl = require('../images/get_bg.png')
        const divStyle = {
            background: 'linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(' + imgUrl + ') no-repeat',
        }
        return (
            <section className="new_call_action_area" style={divStyle} id="projects">
                <div className="container">
                    <div className="mb-5 text-white text-center">
                        <h2 className="wow fadeInUp">Partnerships</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                                <a href="https://multiversx.com/" rel="noopener noreferrer" target="_blank">
                                    <img alt="MultiversX Logo" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/multiversX-logo.webp')} />
                                    <div className="wow fadeInUp">MultiversX Network</div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                                <a href="https://ashswap.com/" rel="noopener noreferrer" target="_blank">
                                    <img alt="AshSwap Logo" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/ashswap-logo.png')} />
                                    <div className="wow fadeInUp">AshSwap - MultiversX's first Stable-Swap</div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                                <a href="https://beta.hatom.com" target="_blank" rel="noopener noreferrer">
                                    <img alt="Hatom Logo" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/hatom-logo.png')} />
                                    <div className="wow fadeInUp">Hatom - The First Liquidity Protocol on MultiversX</div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                                <a href="https://romaniacs.org" target="_blank" rel="noopener noreferrer">
                                    <img alt="Romaniacs Logo" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/romaniacs-logo.png')} />
                                    <div className="wow fadeInUp">Romaniacs - Romaniacs Madness NFT’s</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Partners;
