import React, { Component } from 'react';

class CustomNavbar extends Component {
    render() {
        return (
            <nav id="fixed-top" className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg">
                <div className="container"><a href="#Home"><span className="navbar-brand">
                    <img src={require('../images/logo-titan.svg').default} alt="logo" />
                    <img className="logo-top-small" src={require('../images/Logo-titan-dark.png')} alt="logo" /></span></a><button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                           
                            <li className="nav-item"><a className="nav-link" href="#Home">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="#aboutus">About Us</a></li>
                            <li className="nav-item"><a className="nav-link" href="#team">Team</a></li>
                            <li className="nav-item"><a className="nav-link" href="#mission">Our Mission</a></li>
                            <li className="nav-item"><a className="nav-link" href="#projects">Projects</a></li>
                            <li className="nav-item"><a className="nav-link" href="#faq">FAQ</a></li>
                        </ul>
                        <a rel="noopener noreferrer" target="_blank" href="https://delegate.titanstake.com/" className="get-btn">Stake Now</a>
                    </div>
                </div>
            </nav>
        );
    }
}

export default CustomNavbar;