import React, { Component } from 'react';
import Title from './Title';
import FeaturesItems from './FeaturesItems';

class Features extends Component {
    render() {
        return (
            <section className="best_screen_features_area features_area_pad" id="features">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" id="down_bg_copy_2" data-name="down / bg copy 2" className="cls-1" d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z" />
                </svg>
                <div className="container">
                    <Title Stitle="Why to support MultiversX?" pfont=""/>
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="row b_features_info">
                                <FeaturesItems ftitle="Relentless Development" text="Building a state-of-art secure network does not happen overnight and it is always reassuring to join a phenomenal network that is always developing and sorting out challenges." name="settings"/>
                                <FeaturesItems ftitle="Green Network" text="The network’s proof of stake orientation keeps a minimal carbon footprint whilst operating the network. A disruptive, sustainable, eco-friendly operating network of today, building for tomorrow." name="shine"/>
                                <FeaturesItems ftitle="Efficiency" text="Choosing the network that matches your requirements is a key factor în achieving your project’s success, and whether you are looking for security, speed or low gas fees, MultiversX has it all." name="stats-up"/>
                                <FeaturesItems ftitle="Committed" text="Managing your assets is a serious responsibility and for that we are always upgrading our security protocols, developing the UX, and creating new tools to offer you an unprecedented staking experience." name="key"/>
                                <FeaturesItems ftitle="Builders" text="We are continuously developing more tools, ideas, protocols and projects that enable developers, entrepreneurs and builders which în their turn will enable the ecosystem and contribute în its growth." name="ruler-pencil"/>
                                <FeaturesItems ftitle="Visionaries" text="It is a dynamic world and we put a great effort in keeping up with the market updates and latest trends to be able to develop." name="eye"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;