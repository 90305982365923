import React, { Component } from 'react';

class Action extends Component {
    render() {
        let imgUrl = require('../images/get_bg.png')
        const divStyle = {
            background: 'linear-gradient(0deg, rgba(76,132,255,0.88), rgba(76,132,255,0.88)), url(' + imgUrl + ') no-repeat',
        }
        return (
            <section className="new_call_action_area" style={divStyle} id="projects">
                <div className="container">
                    <div className="d-flex justify-content-center title-four-white"><h2>Our Projects</h2></div>
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                            <a href="https://maiartokens.com/" target="_blank" rel="noopener noreferrer">
                                <img alt="Maiar Tokens" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/logo-maiartokens.png')} />
                                <div className="wow fadeInUp">xExchange price API</div>
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                            <a href="https://egldscan.com/" target="_blank" rel="noopener noreferrer">
                                <img alt="eGLDScan" className="wow img-projects fadeInUp p-4 img-fluid" src={require('../images/egldscan.png')} />
                                <div className="wow fadeInUp">eGLDScan partnership with Staking Agency</div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Action;