import axios from "axios";
import SessionStorageService from "../services/sessionStorageService/SessionStorageService";
import {SessionStorageKeys} from "../services/sessionStorageService/sessionStorageKeys";

const fetchMediumPosts = (url: string) =>
    async function getPosts({timeout}: any) {
        try {
            const getMediumPosts = SessionStorageService.getItem(SessionStorageKeys.mediumPosts);
            if(getMediumPosts){
                return getMediumPosts;
            }

            const {data} = await axios.get(`${url}`, {
                timeout
            });

            const response = {
                data: data,
                success: data !== undefined
            };
            SessionStorageService.setItem(SessionStorageKeys.mediumPosts, response, 600);

            return response;
        } catch (err) {
            return {
                success: false
            };
        }
    };

export const getMediumPosts = fetchMediumPosts("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@titanstake");

