import BigNumber from "bignumber.js";

export class NumberUtils {
    static toDenominatedString(amount: number | string, decimals = 18): string {
        if (!amount) {
            amount = "0";
        }

        let denominatedValue = new BigNumber(amount.toString()).shiftedBy(-decimals).toFixed(decimals);
        if (denominatedValue.includes(".")) {
            denominatedValue = denominatedValue.replace(/0+$/g, "").replace(/\.$/g, "");
        }

        return denominatedValue;
    }

    static numberDecode(encoded: string): string {
        const hex = Buffer.from(encoded, "base64").toString("hex");
        return new BigNumber(hex, 16).toString(10);
    }

    static getPercentage(value: number | string, toFixed: number | undefined = 2): number {
        return +(+value * 100).toFixed(toFixed);
    }

    static numberToDenominatedNumber(amount: number | string): BigNumber {
        if (!amount) {
            return new BigNumber(0);
        }
        const additionalZeros = "000000000000000000";
        let amountStr = amount.toString();
        amountStr = amountStr.indexOf(".") > 0 ? amountStr.replace(".", additionalZeros + ".") : amountStr + additionalZeros;

        return new BigNumber(amountStr);
    }

    static toNumber(value: string | number | null): number | null {
        return !!value ? Number(value) : null;
    }
}
