import React, { Component } from 'react';
import Title from '../Title';
import $ from 'jquery';

class Faq extends Component {
    componentDidMount(){
        $(".faq_accordian_two .card").click(function(){
            $(".faq_accordian_two .card").removeClass("active");
            $(this).addClass('active');
        });
    }
    render() {
        return (
            <section className="faq_solution_area features_area_pad" id="faq">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require ('../../images/line/fq_line-1.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../../images/line/fq_line-2.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../../images/line/fq_line-3.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../../images/line/fq_line-4.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../../images/line/fq_line-5.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../../images/line/fq_line-6.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../../images/line/fq_line-7.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../../images/line/fq_line-8.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": -150}'><img src={require('../../images/line/fq_line-9.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": -180}'><img src={require('../../images/line/fq_line-10.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="How it works?" pfont="Monthly income reports directly in your dashboard account."/>
                    <div className="row ">
                        <div className="col-md-12 d_flex">
                            <div id="accordion" className="faq_accordian_two">
                                <div className="card wow fadeInUp active">
                                    <div className="card-header" id="heading1">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse0" aria-expanded="true" aria-controls="collapse0"><i className='ti-crown'></i>What is a staking provider/validator?</button></h5>
                                    </div>
                                    <div id="collapse0" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Validators/Staking providers are basically companies that offer the infrastructure for running validating nodes and with them you can delegate your tokens to be entitled for a share of the staking rewards’ pool.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading2">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1"><i className='ti-support'></i>Is there 24/7 customer support? </button></h5>
                                    </div>
                                    <div id="collapse1" className="collapse " aria-labelledby="heading2" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Our team is available everyday from 6:00 to 18:00 UTC to answer your inquiries on  Telegram and facebook. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading3">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2"><i className='ti-server'></i>From where do staking rewards come? </button></h5>
                                    </div>
                                    <div id="collapse2" className="collapse " aria-labelledby="heading3" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>In proof-of-stake networks, staking your tokens allows you to become a part of a validator who is rewarded in the network’s currency for supporting the network infrastructure. These rewards come from a decentralized treasury/fund that contains a percentage of the whole token supply that is pre-minted or not generated yet for the rewards’ allocation. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading4">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3"><i className='ti-timer'></i>How long does it take to undelegate my tokens? </button></h5>
                                    </div>
                                    <div id="collapse3" className="collapse " aria-labelledby="heading4" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>After you decide to undelegate, your tokens will be locked 10 days.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading5">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4"><i className='ti-money'></i>Does staking mean gaining more money from a passive income source?  </button></h5>
                                    </div>
                                    <div id="collapse4" className="collapse " aria-labelledby="heading5" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Staking means generating a passive income in the form of APR on your staked amount but the rewards are in the same token, so basically your token holding volume is increasing but this does not necessarily mean your fiat capital growth, as in some unfortunate cases the token values might be decreasing in a higher rate than that of your token volume growth. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading5">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5"><i className='ti-shield'></i>How safe are my tokens? </button></h5>
                                    </div>
                                    <div id="collapse5" className="collapse " aria-labelledby="heading5" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Your tokens are 100% safe with us, it is locked according to a secure smart contract that was carefully designed MultiversX Team. The staking provider dose not have access to your funds, only you can withdraw the tokens. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;