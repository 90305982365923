import React, { Component } from 'react';

class Blog extends Component {
    render() {
        const {posts} = this.props;
        return (
            <section id="blog">
                <div className="container mt-5 mb-5">
                    <div className="mb-5 text-center">
                        <h2 className="wow fadeInUp">Blog</h2>
                    </div>
                    <div className="row">
                        {posts.length > 0 && posts.map((post) =>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="b_features_item wow fadeInUp p-0">
                                    <a href={post.link} rel="noopener noreferrer" target="_blank">
                                        <img class="img img-fluid" src={post.thumbnail} />
                                        <p className="wow fadeInUp mt-4 text-center">{post.title}</p>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default Blog;