import axios from "axios";
import SessionStorageService from "../services/sessionStorageService/SessionStorageService";
import {SessionStorageKeys} from "../services/sessionStorageService/sessionStorageKeys";

interface GetEgldPriceType {
    apiAddress: string;
    timeout?: number;
    page?: number;
    url?: string;
}

const fetchEgldValue = (url: string) =>
    async function getEgldValue({apiAddress, timeout}: GetEgldPriceType) {
        try {
            const getSessionEgldValue = SessionStorageService.getItem(SessionStorageKeys.egldUsdcValue);
            if(getSessionEgldValue){
                return getSessionEgldValue;
            }

            const {data} = await axios.get(`${apiAddress}${url}`, {
                params: {
                    fsym: "WEGLD",
                    tsym: "USDC"
                },
                timeout
            });

            const response = {
                data: data,
                success: data !== undefined
            };
            SessionStorageService.setItem(SessionStorageKeys.egldUsdcValue, response, 60);

            return response;
        } catch (err) {
            return {
                success: false
            };
        }
    };

export const getEgldValue = fetchEgldValue("/token-value");
